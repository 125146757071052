// 引入封装的第一层axios接口
import request from "utils/req";

// 头部目录
export const getStudentBMI = (params) => {
    return request({
        url: '/api/blade-information/information/getStudentBMI',
        method: 'get',
        params: {
            ...params
        }
    })
}


