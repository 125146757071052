<template>
  <div class="container">
    <div class="physique">
      <div>
        <img :src="bmi<bmiMin?low:(bmi<=bmiMax?normal:weight)" width="100px" />
      </div>
      <div class="info">
        <div class="height">当前身高：{{userInfo.height}}厘米</div>
        <div :class="bmi<bmiMin?'weights low':(bmi<=bmiMax?'weights normal':'weights weight')">当前体重：{{userInfo.kg}}KG
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="box">
        <div style="display: flex;align-items: center;padding:0 0 5px 0;">
          <span class="fs_20 fw_bold">BMI{{bmi}}</span>（BMI体重（公斤）/身高（米）的平方）
        </div>
        <div style="padding: 5px 0;display: flex;justify-items: center;">
          <span :class="bmi<bmiMin?'fs_14 low':(bmi<=bmiMax?'fs_14 normal':'fs_14 weight')">
            <span v-if="bmi<bmiMin">偏低</span>
            <span v-else-if="bmi<=bmiMax">标准</span>
            <span v-else>偏高</span>
          </span>
          <span class="progress">
            <div style="border-color: #62b3ff;"></div>
            <div style="border-color: #45e8bd;"></div>
            <div style="border-color: #f48e8e;"></div>
            <div :class="bmi<bmiMin?'progress_value low':(bmi<=bmiMax?'progress_value normal':'progress_value weight')"
              :style="'margin-left:'+process+'%'">
              <div>▼</div>
              <div>{{bmi}}</div>
            </div>
          </span>

        </div>
        <div style="padding: 5px 0;line-height: 18px;text-justify:;" v-html="str">

        </div>
        <div style="display: flex;align-items: center;padding: 5px 0;">
          <span class="fs_16 fw_bold">为您推荐</span>（根据您的BMI值，为您推荐适合您的运动）
        </div>
        <div style="display: flex;justify-content: center;text-align: center;">
          <div style="cursor: pointer;"
            @click="window.open('https://basic.smartedu.cn/schoolService/tydl/catalog?contentId=dea9524a-56e7-4154-954b-1aa5790278af')">
            <img :src="viedo" width="110px" height="110px" style="border-radius: 6px;" />
            <div>点击观看锻炼视频</div>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div style="position: relative;">
            <div class="recommend" v-show="bmi<bmiMin||bmi>bmiMax">推荐</div>
            <img :src="code" width="110px" height="110px" style="border-radius: 6px;" />
            <div>扫码进行体育锻炼</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getStudentBMI
  } from '@/api/bmi/index'
  import normal from '@/assets/image/bmi/normal.png'
  import low from '@/assets/image/bmi/low.png'
  import weight from '@/assets/image/bmi/weight.png'
  import viedo from '@/assets/image/bmi/Mask group@2x.png'
  import code from '@/assets/image/bmi/Mask group2@2x.jpg'
  import {sessionGet} from "@/utils/local";
  export default {
    data() {
      return {
        window,
        normal,
        weight,
        low,
        viedo,
        code,
        str: '',
        userInfo: '',
        bmi: '', //学生的实际bmi
        bmiMin: '', //最小bmi
        bmiMax: '', //最大bmi
        process: '', //标准值占比（%）
      }
    },
    created() {
      //获取bmi
      getStudentBMI({
        userId: sessionGet('userInfo').user_id
      }).then(res => {
        if (res.code == 200) {
          this.bmi = res.data.bmi;
          this.bmiMax = res.data.range.split("-")[1];
          this.bmiMin = res.data.range.split("-")[0];
          this.userInfo = res.data;
        }else{
          this.$message.error(res.msg);
        }
        if (this.bmi < this.bmiMin) {
          this.str = '您当前体重为' + res.data.kg + 'KG，BMI指数为：' + res.data.bmi +
            '，<span style="color: #62b3ff;">低于标准范围</span>，BMI值偏低会导致人体的抵抗力下降，容易感觉疲劳，为保持健康身体，要加强运动，增加营养，养成良好的睡眠习惯，早睡、早起，同时调整好自己的心态。'
        } else if (this.bmi > this.bmiMax) {
          this.str = '您当前体重为' + res.data.kg + 'KG，BMI指数为：' + res.data.bmi +
            '，<span style="color: #f48e8e;">高于标准范围</span>，BMI偏高可能存在肥胖问题，一般可以通过饮食控制或者是适当的锻炼来进行改善,饮食上一定要避免摄入油腻性或者是油炸性食物，每天保持40分钟有氧运动。'
        } else {
          this.str = '您当前体重为' + res.data.kg + 'KG，BMI指数为：' + res.data.bmi +
            '，<span style="color: #45e8bd;">属于正常范围</span>，继续保持哦！继续健康的生活方式，坚持运动，保持合理作息和饮食习惯，让身体一直保持年轻和活力。'
        }
        this.getProcess();
      })

    },
    methods: {
      //获取bmi占比
      getProcess() {
        //偏瘦
        if (this.bmi < this.bmiMin) {
          this.process = 27 + ((this.bmi - this.bmiMin) / (this.bmiMax - this.bmiMin)) * (60 - 27);
        } else if (this.bmi > this.bmiMax) {
          //偏胖
          if (this.bmi > 26.7) {
            this.process = 93;
          } else {
            this.process = 27 + ((this.bmi - this.bmiMin) / (this.bmiMax - this.bmiMin)) * (60 - 27);
          }
        } else {
          //正常
          if (this.bmi - this.bmiMin == 0) {
            this.process = 27;
          } else if (this.bmi - this.bmiMax == 0) {
            this.process = 60;
          } else {
            this.process = 27 + ((this.bmi - this.bmiMin) / (this.bmiMax - this.bmiMin)) * (60 - 27);
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  // .container::after {
  //   display: block;
  //   content: "◆";
  //   position: absolute;
  //   font-size: 50px;
  //   left: 325px;
  //   bottom: -31px;
  //   color: white;
  // }

  /*注意：此处::before的content要比::after的bottom高1px，就形成了箭头视觉效果了*/
  // .container::before {
  //   display: block;
  //   content: "◆";
  //   position: absolute;
  //   font-size: 50px;
  //   left: 325px;
  //   bottom: -32px;
  //   color: #171617;
  // }
  .container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 650px;
    height:340px;
    // border: 1px #171617 solid;
    border-radius: 6px;
     background-color: #fff;
    align-items: center;

    .physique {
      justify-content: center;
      width: 40%;
      display: flex;
      flex-direction: row;

      .info {
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        .weights.weight {
          border: 1px #f48e8e solid;
          padding: 3px 8px;
          border-radius: 25px;
        }

        .weights.low {
          border: 1px #62b3ff solid;
          padding: 3px 8px;
          border-radius: 25px;
        }

        .weights.normal {
          border: 1px #45e8bd solid;
          padding: 3px 8px;
          border-radius: 25px;
        }

        .height {
          border: 1px #8fecd4 solid;
          padding: 3px 8px;
          border-radius: 25px;
        }
      }
    }

    .detail {
      width: 60%;
      padding: 15px 15px 15px 0;

      .box {
        background-color: #f5f9ff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .progress {
          display: flex;
          position: relative;
          width: 58%;
          margin-left: 10px;
          align-items: center;

          div {
            width: 33%;
            border: 2px solid;
          }
        }

        .progress_value {
          left: 0;
          border: none !important;
          position: absolute;
          text-align: center;

          div {
            border: none !important;
          }
        }

        .recommend {
          position: absolute;
          right: -18px;
          top: -8px;
          background-color: #fd5252;
          color: #fff;
          padding: 5px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 20px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
    }

    .low {
      color: #62b3ff;
    }

    .normal {
      color: #45e8bd;
    }

    .weight {
      color: #f48e8e;
    }
  }
</style>
